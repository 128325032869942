import PageHeader from '~/components/PageHeader';
import notFoundImage from "../../../../../content/home/notFoundImage.jpg";
import { Paragraph, Heading2, Link } from '@entur/typography';
import { PrimaryButton } from '@entur/button';
import * as React from 'react';
export default {
  PageHeader,
  notFoundImage,
  Paragraph,
  Heading2,
  Link,
  PrimaryButton,
  React
};