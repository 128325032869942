module.exports = [{
      plugin: require('../../../../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[[null,{"type":"yaml","marker":"-"}],null],"rehypePlugins":[null,null],"gatsbyRemarkPlugins":[],"defaultLayouts":{"default":"/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js"}},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../../node_modules/gatsby-theme-docz/gatsby-browser.js'),
      options: {"plugins":[],"themeConfig":{},"src":"content","gatsbyRoot":null,"themesDir":"src","mdxExtensions":[".md",".mdx"],"docgenConfig":{"searchPath":"../../node_modules/@entur"},"menu":[],"mdPlugins":[],"hastPlugins":[],"ignore":["README.md","CHANGELOG.md","../../packages/*/node_modules"],"typescript":true,"ts":false,"propsParser":true,"props-parser":true,"debug":false,"native":false,"openBrowser":null,"o":null,"open":null,"open-browser":null,"root":"/home/circleci/project/apps/documentation/.docz","base":"/","source":"./","gatsby-root":null,"files":"**/*.{md,markdown,mdx}","public":"/public","dest":"dist","d":".docz/dist","editBranch":"master","eb":"master","edit-branch":"master","config":"","title":"Entur Designsystem","description":"My awesome app using docz","host":"localhost","port":3000,"p":3000,"separator":"-","paths":{"root":"/home/circleci/project/apps/documentation","templates":"/home/circleci/project/node_modules/docz-core/dist/templates","docz":"/home/circleci/project/apps/documentation/.docz","cache":"/home/circleci/project/apps/documentation/.docz/.cache","app":"/home/circleci/project/apps/documentation/.docz/app","appPackageJson":"/home/circleci/project/apps/documentation/package.json","appTsConfig":"/home/circleci/project/apps/documentation/tsconfig.json","gatsbyConfig":"/home/circleci/project/apps/documentation/gatsby-config.js","gatsbyBrowser":"/home/circleci/project/apps/documentation/gatsby-browser.js","gatsbyNode":"/home/circleci/project/apps/documentation/gatsby-node.js","gatsbySSR":"/home/circleci/project/apps/documentation/gatsby-ssr.js","importsJs":"/home/circleci/project/apps/documentation/.docz/app/imports.js","rootJs":"/home/circleci/project/apps/documentation/.docz/app/root.jsx","indexJs":"/home/circleci/project/apps/documentation/.docz/app/index.jsx","indexHtml":"/home/circleci/project/apps/documentation/.docz/app/index.html","db":"/home/circleci/project/apps/documentation/.docz/app/db.json"}},
    },{
      plugin: require('../../../../node_modules/gatsby-plugin-posthog/gatsby-browser.js'),
      options: {"plugins":[],"apiKey":"phc_ESGRM1feMLZkHxV0P81O4i7g4I4jTFIYZpuZVxqF3hq","apiHost":"https://eu.posthog.com","head":true,"initOptions":{"persistence":"memory","disable_session_recording":true}},
    }]
