import { graphql } from "gatsby";
import Img from "gatsby-image";
import { GridItem, GridContainer } from '@entur/grid';
import { data, space } from '@entur/tokens';
import { Heading5, Paragraph, StrongText, UnorderedList, ListItem } from '@entur/typography';
import BaseCardDesignEntur from '~/components/BaseCardDesignEntur';
import PageHeader from '~/components/PageHeader';
import "../../../../../content/identitet/index.scss";
import * as React from 'react';
export default {
  graphql,
  Img,
  GridItem,
  GridContainer,
  data,
  space,
  Heading5,
  Paragraph,
  StrongText,
  UnorderedList,
  ListItem,
  BaseCardDesignEntur,
  PageHeader,
  React
};