import { Paragraph } from '@entur/typography';
import PageHeader from '~/components/PageHeader';
import ColorsDataVisualisation, { DataIllustrations } from '~/components/ColorsDataVisualisation';
import Vektlegging from "../../../../../content/identitet/verktoykassen/DatavisualiseringVektlegging.png";
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import dataDont1 from "../../../../../content/identitet/verktoykassen/dataDont1.png";
import dataDont2 from "../../../../../content/identitet/verktoykassen/dataDont2.png";
import * as React from 'react';
export default {
  Paragraph,
  PageHeader,
  ColorsDataVisualisation,
  DataIllustrations,
  Vektlegging,
  DoDontGroup,
  DoDontCard,
  dataDont1,
  dataDont2,
  React
};