import { borderWidths, colors, space } from '@entur/tokens';
import { Table, TableHead, TableRow, HeaderCell, TableBody } from '@entur/table';
import { GridContainer } from '@entur/grid';
import { DoDontGroup, DoDontCard } from '~/components/DoDont';
import dont1 from "../../../../../content/identitet/verktoykassen/farger/Dont1.png";
import dont2 from "../../../../../content/identitet/verktoykassen/farger/Dont2.png";
import dont3 from "../../../../../content/identitet/verktoykassen/farger/Dont3.png";
import dont4 from "../../../../../content/identitet/verktoykassen/farger/Dont4.png";
import balance from "../../../../../content/identitet/verktoykassen/farger/ColorBalance.png";
import Colors from '~/components/Colors';
import PageHeader from '~/components/PageHeader';
import * as React from 'react';
export default {
  borderWidths,
  colors,
  space,
  Table,
  TableHead,
  TableRow,
  HeaderCell,
  TableBody,
  GridContainer,
  DoDontGroup,
  DoDontCard,
  dont1,
  dont2,
  dont3,
  dont4,
  balance,
  Colors,
  PageHeader,
  React
};