import PageHeader from '~/components/PageHeader';
import { Paragraph, Heading5 } from '@entur/typography';
import { GridContainer, GridItem } from '@entur/grid';
import { BannerAlertBox } from '@entur/alert';
import { DownloadBrushes } from '~/gatsby-theme-docz/components/DownloadBrushes';
import farge1 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/Typo-steder-korall-kaviar.png";
import farge2 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/Typo-steder-korall.png";
import farge3 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/Typo-steder.png";
import farge4 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/Typo-steder-blue.png";
import linjeavstand from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/Typo-linjeavstand.png";
import strek1 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/EN_strek_typer.png";
import strek2 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/EN_strek_patterns.png";
import strek3 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/EN_strek_linjeavstand.png";
import strek4 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/EN_strek_forhold.png";
import strek5 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/EN_strek_plassering.png";
import strek6 from "../../../../../content/identitet/verktoykassen/Grafiskeelementer/EN_strek_str.png";
import * as React from 'react';
export default {
  PageHeader,
  Paragraph,
  Heading5,
  GridContainer,
  GridItem,
  BannerAlertBox,
  DownloadBrushes,
  farge1,
  farge2,
  farge3,
  farge4,
  linjeavstand,
  strek1,
  strek2,
  strek3,
  strek4,
  strek5,
  strek6,
  React
};