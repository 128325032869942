import { BannerAlertBox } from '@entur/alert';
import { Link } from 'docz';
import { Link as LinkText } from '@entur/typography';
import { space } from '@entur/tokens';
import PageHeader from '~/components/PageHeader';
import { TypographyDisplay } from '~/components/TypographyDisplay';
import { ImageDisplay } from '~/components/ImageDisplay';
import * as React from 'react';
export default {
  BannerAlertBox,
  Link,
  LinkText,
  space,
  PageHeader,
  TypographyDisplay,
  ImageDisplay,
  React
};