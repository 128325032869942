import { GridContainer, GridItem } from '@entur/grid';
import { colors } from '@entur/tokens';
import { StrongText, Paragraph } from '@entur/typography';
import bilde1 from "../../../../../content/identitet/verktoykassen/foto/bilde1.jpg";
import bilde2 from "../../../../../content/identitet/verktoykassen/foto/bilde2.jpg";
import bilde3 from "../../../../../content/identitet/verktoykassen/foto/bilde3.jpg";
import bilde4 from "../../../../../content/identitet/verktoykassen/foto/bilde4.jpg";
import PageHeader from '~/components/PageHeader';
import * as React from 'react';
export default {
  GridContainer,
  GridItem,
  colors,
  StrongText,
  Paragraph,
  bilde1,
  bilde2,
  bilde3,
  bilde4,
  PageHeader,
  React
};